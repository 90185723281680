import { Container, Stack } from "@mui/material";
import AppLogo from "./AppLogo";
import AppMenu from "./AppMenu";
import AppMenuDrawer from "./AppMenuDrawer";

const AppHeader = () => {
  return (
    <>
      <Container maxWidth="lg">
        <Stack
          direction={"row"}
          sx={{
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <AppLogo />
          {window.innerWidth < 800 ? <AppMenuDrawer /> : <AppMenu />}
        </Stack>
      </Container>
    </>
  );
};

export default AppHeader;
