import { Box, Container, Stack, Typography } from "@mui/material";
import WhiteLogo from "../assets/logo1.png";
import LinkedIn from "../assets/linkedin.png";
import Facebook from "../assets/facebook.png";
import Twitter from "../assets/twitter.png";
import Address from "../assets/address.png";
import Email from "../assets/email.png";
import Phone from "../assets/phone.png";
import AppMenu from "./AppMenu";

const AppFooter = () => {
  return (
    <>
      <Box
        sx={{
          background: "#006594",
          p: 5,
        }}
      >
        <Container maxWidth="lg">
          <Box
            sx={(theme) => ({
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-start",
              [theme.breakpoints.down("md")]: {
                flexDirection: "column",
              },
            })}
          >
            <Stack
              gap={3}
              sx={{
                maxWidth: "500px",
              }}
            >
              <Box>
                <img
                  src={WhiteLogo}
                  alt="smarthawk footer logo"
                  height={"60px"}
                />
              </Box>
              <Box>
                <Typography
                  variant="body2"
                  sx={{
                    color: "#ffffff",
                  }}
                >
                  Smarthawk is a dynamic web design and development company
                  dedicated to enhancing your brand's online presence and
                  visibility. We specialize in creating stunning, user-friendly
                  websites tailored to meet your unique business needs. In
                  addition to bespoke web solutions, we develop innovative
                  products designed to accelerate your business growth. With a
                  commitment to excellence and a focus on results, Smarthawk
                  empowers clients to achieve their digital goals and stand out
                  in a competitive market.
                </Typography>
              </Box>
              <Box>
                <Stack direction={"row"} gap={2}>
                  <Box>
                    <a href="/">
                      <img src={LinkedIn} alt="Linkedin icon" height={"30px"} />
                    </a>
                  </Box>
                  <Box>
                    <a href="/">
                      <img src={Facebook} alt="Facebook icon" height={"30px"} />
                    </a>
                  </Box>
                  <Box>
                    <a href="/">
                      <img src={Twitter} alt="X icon" height={"30px"} />
                    </a>
                  </Box>
                </Stack>
              </Box>
            </Stack>
            <Box>
              <AppMenu vertical gap={2} />
            </Box>
            <Stack
              sx={{
                p: 3,
              }}
            >
              <Box
                sx={{
                  mb: 3,
                }}
              >
                <Stack direction={"row"} gap={2}>
                  <Box>
                    <img src={Address} alt="Address icon" />
                  </Box>
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: "16px",
                      color: "#ffffff",
                    }}
                  >
                    Address
                  </Typography>
                </Stack>
                <Typography
                  variant="body1"
                  sx={{
                    color: "#ffffff",
                  }}
                >
                  Smarthawk Technologies LLP
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    color: "#ffffff",
                  }}
                >
                  Thane 400608
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    color: "#ffffff",
                  }}
                >
                  India
                </Typography>
              </Box>
              <Box
                sx={{
                  mb: 3,
                }}
              >
                <Stack direction={"row"} gap={2}>
                  <Box>
                    <img src={Phone} alt="Phone icon" />
                  </Box>
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: "16px",
                      color: "#ffffff",
                    }}
                  >
                    Whatsapp
                  </Typography>
                </Stack>
                <Typography
                  variant="body1"
                  sx={{
                    color: "#ffffff",
                  }}
                >
                  +91-9167146779
                </Typography>
              </Box>
              <Box
                sx={{
                  mb: 3,
                }}
              >
                <Stack direction={"row"} gap={2}>
                  <Box>
                    <img src={Email} alt="Email icon" />
                  </Box>
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: "16px",
                      color: "#ffffff",
                    }}
                  >
                    Email
                  </Typography>
                </Stack>
                <Typography
                  variant="body1"
                  sx={{
                    color: "#ffffff",
                  }}
                >
                  contact@smarthawk.co.in
                </Typography>
              </Box>
            </Stack>
          </Box>
          {/* <Divider
            sx={{
              border: "1px solid #ffffff",
            }}
          /> */}
          <Box
            sx={{
              pt: "50px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                color: "#ffffff",
              }}
            >
              &copy; Smarthawk Technologies LLP - 2022. All Rights Reserved.
            </Typography>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default AppFooter;
