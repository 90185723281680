import { Box, Container, Typography } from "@mui/material";
import ContactForm from "../ContactForm";

const ContactUs = () => {
  return (
    <>
      <Box
        id="contact"
        sx={{
          mb: "100px",
        }}
      >
        <Box>
          <Typography
            variant="h3"
            sx={{
              mb: "50px",
              color: "#006594",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            Contact us
          </Typography>
          <Container maxWidth="lg">
            <ContactForm />
          </Container>
        </Box>
      </Box>
    </>
  );
};

export default ContactUs;
