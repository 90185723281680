import { Box, Stack, Typography } from "@mui/material";

interface IProcessStage {
  title: string;
  description: string;
  icon: any;
  step: number;
}

const ProcessStage = ({ title, description, icon, step }: IProcessStage) => {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          mb: 5,
          width: "350px",
          flexGrow: 1,
        }}
      >
        <Box
          sx={{
            background: "#ffffff",
            boxShadow: "0px 1px 5px #ececec",
            borderRadius: "8px",
            p: 3,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexGrow: 1,
          }}
        >
          <Stack
            gap={3}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                marginTop: "-50px",
                borderRadius: "100px",
                background: "#006594",
                height: "50px",
                width: "50px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img height={20} width={20} src={icon} alt="process stage icon" />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  color: "#006594",
                }}
              >
                Step {step}
              </Typography>
              <Typography
                variant="h5"
                sx={{
                  color: "#006594",
                }}
              >
                {title}
              </Typography>
            </Box>
            <Typography
              variant="body2"
              sx={{
                textAlign: "center",
              }}
            >
              {description}
            </Typography>
          </Stack>
        </Box>
      </Box>
    </>
  );
};

export default ProcessStage;
