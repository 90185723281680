import {
  Box,
  Button,
  IconButton,
  Snackbar,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import axios from "axios";
import { Close } from "@mui/icons-material";
import validator from "validator";

const ContactForm = () => {
  const [name, updateName] = useState<string>();
  const [email, updateEmail] = useState<string>();
  const [phone, updatePhone] = useState<number>();
  const [message, updateMessage] = useState<string>();
  const [info, updateInfo] = useState<string>("");
  const [ready, updateReady] = useState<boolean>(false);

  useEffect(() => {}, [name, email, phone, message]);

  const clearMessage = () => {
    updateName("");
    updateEmail("");
    updatePhone(undefined);
    updateMessage("");
  };

  const isNameValid = useMemo(() => {
    if (name && name.length) {
      return true;
    }
    return false;
  }, [name]);

  const isEmailValid = useMemo(() => {
    return email && email.length && validator.isEmail(email);
  }, [email]);

  const isPhoneValid = useMemo(() => {
    return phone && `${phone}`.length;
  }, [phone]);

  const isMessageValid = useMemo(() => {
    return message && message.length;
  }, [message]);

  useEffect(() => {
    if (name && !isNameValid) {
      updateInfo("Name is not valid");
    }
    if (name && !isEmailValid) {
      updateInfo("Email is not valid");
    }
    if (message && !isMessageValid) {
      updateInfo("Message is not valid");
    }
    if (isNameValid && isEmailValid && isMessageValid) {
      updateReady(true);
    }
  }, [
    name,
    email,
    message,
    isNameValid,
    isEmailValid,
    isPhoneValid,
    isMessageValid,
  ]);

  const submitForm = useCallback(() => {
    if (name === "" || email === "" || message === "") {
      return updateInfo("All fields marked with * are mandatory");
    }
    axios
      .post("/api/v1/email/send", {
        to: "contact@smarthawk.co.in",
        subject: "[NEW LEAD] from Smarthawk landing page",
        body: `
      <!DOCTYPE html>
<html>
<head>
    <title>Form Submission</title>
    <style>
        body {
            font-family: Arial, sans-serif;
        }
        .container {
            margin: 20px;
            padding: 20px;
            border: 1px solid #ddd;
            border-radius: 5px;
            background-color: #f9f9f9;
        }
        .header {
            font-size: 18px;
            font-weight: bold;
            margin-bottom: 10px;
        }
        .field {
            margin-bottom: 15px;
        }
        .field label {
            display: block;
            font-weight: bold;
            margin-bottom: 5px;
        }
        .field .value {
            font-size: 16px;
            color: #333;
        }
    </style>
</head>
<body>
     <div class="container">
        <div class="field">
            <label>Name:</label>
            <div class="value">${name}</div>
        </div>
        <div class="field">
            <label>Email:</label>
            <div class="value">${email}</div>
        </div>
        <div class="field">
            <label>Phone:</label>
            <div class="value">${phone ?? 0}</div>
        </div>
        <div class="field">
            <label>Message:</label>
            <div class="value">${message}</div>
        </div>
    </div>
</body>
</html>`,
      })
      .then((response) => {
        console.log(response);
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        clearMessage();
      });
  }, [name, email, phone, message]);

  return (
    <>
      <Box
        sx={{
          width: "100%",
          p: 5,
          backgroundColor: "rgba(0, 101, 148, 0.1)",
          borderRadius: "5px",
        }}
      >
        <Stack gap={2}>
          <Box
            sx={{
              width: "100%",
            }}
          >
            <Typography
              sx={{
                mb: "10px",
              }}
              variant="body1"
            >
              Name
            </Typography>
            <TextField
              sx={{
                background: "#ffffff",
                width: "100%",
              }}
              name="NameField"
              placeholder="Enter Name"
              focused
              value={name ?? ""}
              onChange={(e) => updateName(e.target.value)}
            />
          </Box>
          <Box
            sx={{
              width: "100%",
            }}
          >
            <Typography
              sx={{
                mb: "10px",
              }}
              variant="body1"
            >
              Email
            </Typography>
            <TextField
              sx={{
                background: "#ffffff",
                width: "100%",
              }}
              type="email"
              name="EmailField"
              focused
              placeholder="user@abc.com"
              value={email ?? ""}
              onChange={(e) => updateEmail(e.target.value)}
            />
          </Box>
          <Box
            sx={{
              width: "100%",
            }}
          >
            <Typography
              sx={{
                mb: "10px",
              }}
              variant="body1"
            >
              Phone
            </Typography>
            <TextField
              sx={{
                background: "#ffffff",
                width: "100%",
              }}
              name="PhoneField"
              placeholder="+44-1234567890"
              focused
              value={phone}
              onChange={(e) => updatePhone(+e.target.value)}
            />
          </Box>
          <Box
            sx={{
              width: "100%",
            }}
          >
            <Typography
              sx={{
                mb: "10px",
              }}
              variant="body1"
            >
              Message
            </Typography>
            <TextField
              sx={{
                background: "#ffffff",
                width: "100%",
              }}
              multiline
              name="messageField"
              placeholder="Enter Message"
              rows={4}
              focused
              value={message ?? ""}
              onChange={(e) => updateMessage(e.target.value)}
            />
          </Box>
          <Box
            sx={{
              mt: 3,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              onClick={() => {
                submitForm();
              }}
              disabled={!ready}
              sx={(theme) => ({
                minWidth: "500px",
                height: "50px",
                background: "rgba(0, 101, 148, 1)",
                color: "#ffffff",
                "& :hover": {
                  background: "rgba(0, 101, 148, 1)",
                  color: "#ffffff",
                },
                [theme.breakpoints.down("md")]: {
                  width: "fit-content",
                  minWidth: "200px !important",
                },
              })}
            >
              Submit
            </Button>
          </Box>
          <Box>
            <Snackbar
              open={info.length > 0}
              autoHideDuration={6000}
              onClose={() => updateInfo("")}
              message={info}
              action={
                <>
                  <IconButton
                    size="small"
                    aria-label="close"
                    color="inherit"
                    onClick={() => updateInfo("")}
                  >
                    <Close fontSize="small" />
                  </IconButton>
                </>
              }
            />
          </Box>
        </Stack>
      </Box>
    </>
  );
};

export default ContactForm;
