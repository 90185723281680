import { Box, Container, Stack, Typography } from "@mui/material";
import ProcessStage from "../ProcessStage";
import AnalyseIcon from "../../assets/analyse.png";
import DesignIcon from "../../assets/design.png";
import DevelopIcon from "../../assets/develop.png";
import DeployIcon from "../../assets/deploy.png";
import ManageIcon from "../../assets/manage.png";

const ProcessSections = [
  {
    title: "Analyse",
    description:
      "In the planning stage, we collaborate closely with our clients to understand their unique needs and objectives. We conduct thorough research, outline project requirements, and create a detailed roadmap to ensure a clear and structured approach to achieving your goals.",
    icon: AnalyseIcon,
  },
  {
    title: "Design",
    description:
      "Our design phase focuses on creating visually appealing and user-friendly interfaces. We craft wireframes and mockups, ensuring the design aligns with your brand identity and enhances the user experience, resulting in a seamless and engaging product.",
    icon: DesignIcon,
  },
  {
    title: "Develop",
    description:
      "During development, our skilled developers bring the designs to life using the latest technologies and best practices. We write clean, efficient code and build robust, scalable solutions tailored to your specific requirements, ensuring high performance and reliability.",
    icon: DevelopIcon,
  },
  {
    title: "Deploy",
    description:
      "In the deployment stage, we carefully launch your product or service, ensuring everything is fully functional and optimized for the best user experience. We conduct thorough testing and quality assurance to identify and resolve any issues before going live.",
    icon: DeployIcon,
  },
  {
    title: "Manage",
    description:
      "Our management phase ensures your product remains up-to-date and continues to perform optimally. We provide ongoing support, maintenance, and updates, addressing any issues promptly and implementing enhancements to keep your solution ahead of the curve.",
    icon: ManageIcon,
  },
];

const OurProcess = () => {
  return (
    <>
      <Box
        id="process"
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          mb: "100px",
        }}
      >
        <Stack
          gap={2}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mb: "100px",
          }}
        >
          <Typography
            variant="h3"
            sx={{
              color: "#006594",
            }}
          >
            How we do it?
          </Typography>
          <Typography variant="body2">
            A brief overview of the process Smarthawk follows:
          </Typography>
        </Stack>
        <Container maxWidth="lg">
          <Stack direction={"row"} gap={5} flexWrap={"wrap"} flexGrow={1}>
            {ProcessSections.map((p, index) => (
              <>
                <ProcessStage
                  step={index + 1}
                  title={p.title}
                  description={p.description}
                  icon={p.icon}
                />
              </>
            ))}
          </Stack>
        </Container>
      </Box>
    </>
  );
};

export default OurProcess;
