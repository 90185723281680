import { Box, Container, Stack, Typography } from "@mui/material";
import PennyWorthImg from "../../assets/pennyworth.png";

const OurProducts = () => {
  return (
    <>
      <Box
        id="products"
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Stack gap={3}>
          <Typography
            variant="h3"
            sx={{
              mb: "50px",
              color: "#006594",
            }}
          >
            Our Product
          </Typography>
        </Stack>
      </Box>
      <Box
        sx={{
          background: "#006594",
          minHeight: "300px",
          p: 5,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mb: "100px",
        }}
      >
        <Container maxWidth="lg">
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "relative",
            }}
          >
            <Stack
              gap={3}
              sx={{
                width: "100%",
              }}
            >
              <Typography
                variant="h4"
                sx={{
                  color: "#ffffff",
                }}
              >
                PennyWorth
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  maxWidth: "700px",
                  color: "#ffffff",
                }}
              >
                Pennyworth is an innovative and comprehensive restaurant
                management software designed to streamline and enhance the
                efficiency of your restaurant operations. Whether you are
                running a small bistro, a bustling cafe, or a high-end
                restaurant, Pennyworth offers a suite of powerful tools to help
                you manage every aspect of your business effortlessly.
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyItems: "flex-start",
                  alignItems: "center",
                }}
              >
                <a
                  href={"/#"}
                  style={{
                    backgroundColor: "#ffffff",
                    color: "#006594",
                    height: "50px",
                    width: "200px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    textDecoration: "none",
                    borderRadius: "5px",
                    cursor: "default",
                  }}
                >
                  <span>Coming Soon</span>
                </a>
              </Box>
            </Stack>
            <Box
              sx={{
                position: "absolute",
                maxHeight: "400px",
                right: 0,
              }}
            >
              <img
                src={PennyWorthImg}
                alt="for pennyworth"
                height={"100%"}
                width={"100%"}
              />
            </Box>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default OurProducts;
