import { Box, Container, Stack, Typography } from "@mui/material";
import AboutImg from "../../assets/about.png";

const AboutSection = () => {
  return (
    <>
      <Box
        id="about"
        sx={(theme) => ({
          height: "600px",
          width: "100%",
          background: "rgba(0, 101, 148, 0.1)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mb: "100px",
          [theme.breakpoints.down("md")]: {
            height: "100%",
          },
        })}
      >
        <Container
          maxWidth={"lg"}
          sx={(theme) => ({
            [theme.breakpoints.down("md")]: {
              paddingBottom: "40px",
            },
          })}
        >
          <Stack
            direction={"row"}
            sx={(theme) => ({
              display: "flex",
              flex: 2,
              justifyContent: "center",
              alignItems: "center",
              [theme.breakpoints.down("md")]: {
                flexDirection: "column-reverse",
              },
            })}
          >
            <Box
              sx={(theme) => ({
                display: "flex",
                flex: 1,
                width: "50%",
                [theme.breakpoints.down("md")]: {
                  width: "100%",
                },
              })}
            >
              <Stack gap={3}>
                <Typography
                  variant="h2"
                  sx={(theme) => ({
                    color: "#006594",
                    [theme.breakpoints.down("md")]: {
                      fontSize: "32px",
                    },
                  })}
                >
                  What we do?
                </Typography>
                <Stack gap={2}>
                  <Typography variant="body1">
                    Welcome to <b>Smarthawk</b>! We are a premier software
                    company specializing in developing innovative products and
                    delivering top-notch web and mobile design and development
                    services.
                  </Typography>
                  <Typography variant="body1">
                    Our team of <b>expert developers and designers</b> is
                    dedicated to creating tailored solutions that meet the
                    unique needs of our clients. Whether you're looking to build
                    a robust web application, a sleek mobile app, or need a
                    comprehensive design overhaul, Smarthawk is here to
                    transform your vision into reality with efficiency and
                    excellence.
                  </Typography>
                  <Typography variant="body1">
                    Partner with us to leverage cutting-edge technology and
                    achieve <b>your business goals</b>.
                  </Typography>
                </Stack>
                <Box>
                  <a
                    style={{
                      background: "#006594",
                      fontSize: "14px",
                      color: "#ffffff",
                      padding: "10px 15px",
                      borderRadius: "5px",
                      textDecoration: "none",
                    }}
                    href={"#contact"}
                  >
                    Contact Us
                  </a>
                </Box>
              </Stack>
            </Box>
            <Box
              sx={(theme) => ({
                display: "flex",
                flex: 1,
                width: "50%",
                justifyContent: "flex-end",
                alignItems: "center",
                [theme.breakpoints.down("md")]: {
                  width: "100%",
                  marginBottom: "20px",
                },
              })}
            >
              <img
                style={{
                  height: "100%",
                  width: "100%",
                }}
                src={AboutImg}
                alt="about us section"
              />
            </Box>
          </Stack>
        </Container>
      </Box>
    </>
  );
};

export default AboutSection;
