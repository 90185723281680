import { Box, Stack } from "@mui/material";

const MENUS = [
  {
    label: "Home",
    path: "/",
  },
  {
    label: "What we do?",
    path: "#about",
  },
  {
    label: "How we do it?",
    path: "#process",
  },
  {
    label: "Our Products",
    path: "#products",
  },
  {
    label: "Contact us",
    path: "#contact",
  },
];

interface IAppMenu {
  vertical?: boolean;
  gap?: number;
}

const AppMenu = ({ vertical = false, gap = 3 }: IAppMenu) => {
  return (
    <Box
      sx={{
        p: 3,
      }}
    >
      <Stack direction={vertical ? "column" : "row"} gap={gap}>
        {MENUS.map((m) => (
          <a
            style={{
              color: "#000000",
              fontWeight: "16px",
              textDecoration: "none",
            }}
            href={m.path}
          >
            {m.label}
          </a>
        ))}
      </Stack>
    </Box>
  );
};

export default AppMenu;
