import { Box } from "@mui/material";
import AppHeader from "./AppHeader";
import AppFooter from "./AppFooter";

interface IAppLayoutProps {
  children: any;
}

const AppLayout = ({ children }: IAppLayoutProps) => {
  return (
    <>
      <Box>
        <AppHeader />
        {children}
        <AppFooter />
      </Box>
    </>
  );
};

export default AppLayout;
