import "./App.css";
import AppLayout from "./components/AppLayout";
import AboutSection from "./components/sections/About";
import ContactUs from "./components/sections/Contact";
import OurProducts from "./components/sections/OurProducts";
import OurProcess from "./components/sections/Process";

function App() {
  return (
    <div className="App">
      <AppLayout>
        <AboutSection />
        <OurProcess />
        <OurProducts />
        <ContactUs />
      </AppLayout>
    </div>
  );
}

export default App;
