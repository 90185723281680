import { Box } from "@mui/material";
import SmarthawkLogo from "../assets/logo.png";

const AppLogo = () => {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          alt="Smarthawk logo"
          src={SmarthawkLogo}
          height={"80px"}
          width={"80px"}
        />
      </Box>
    </>
  );
};

export default AppLogo;
